/* eslint-disable class-methods-use-this */

import Service from './Service';

class StructureService extends Service {
  constructor() {
    super('structures');
  }

  getFieldsTableStructures() {
    return [
      {
        key: 'type',
        label: 'Tipo de estrutura',
        tdClass: 'text-center text-nowrap',
        thClass: 'text-center text-nowrap',
      },
      {
        key: 'price',
        label: 'Custo',
        tdClass: 'text-center text-nowrap',
        thClass: 'text-center text-nowrap',
      },
      {
        key: 'actions',
        label: 'Ações',
        tdClass: 'text-center text-nowrap',
        thClass: 'text-center text-nowrap',
      },
    ];
  }
}

const structureService = new StructureService();
export default structureService;

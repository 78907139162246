<template>
    <div>
      <b-row class="card mt-5">
        <div class="w-100">
          <h5 class="title-card">
            <i class="fas fa-microchip"></i> Dados da Estrutura
          </h5>
        </div>
        <div class="w-100 p-3">
          <b-form>
            <b-row>
              <b-col lg="4">
                <b-form-group label="Descrição da estrutura">
                  <b-form-input
                    :class="{ 'form-group--error': $v.payload.label.$error }"
                      v-model="$v.payload.label.$model"
                      :state="$v.payload.label.$dirty ?
                      !$v.payload.label.$error : null"
                  />
                  <b-form-invalid-feedback>
                    Preenchimento obrigatório
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>

              <b-col lg="3">
                <b-form-group label="Preço custo">
                  <money
                    class="form-control"
                    :class="{ 'form-group--error': $v.payload.price.$error }"
                    v-model="$v.payload.price.$model"
                    :state="$v.payload.price.$dirty ?
                    !$v.payload.price.$error : null"
                    v-bind="money"
                  />
                  <b-form-invalid-feedback>
                    Preenchimento obrigatório
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>

              <div class="w-100 text-left mt-3">
                    <b-button class="ml-3" variant="success" @click="save">Salvar</b-button>
                </div>
            </b-row>
          </b-form>
        </div>
      </b-row>
    </div>
  </template>

<script>
import { required } from 'vuelidate/lib/validators';
import { Money } from 'v-money';
import MessagesMixin from '@/mixins/messages-mixin';
import QuestionMixin from '@/mixins/question-mixin';
import ErrorMixin from '@/mixins/error-mixin';
import StructureService from '../../../Services/StructureService';

export default {
  props: ['id'],
  components: {
    Money,
  },
  mixins: [MessagesMixin, QuestionMixin, ErrorMixin],
  data() {
    return {
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        precision: 2,
        masked: false, /* doesn't work with directive */
      },
      payload: {
        label: '',
        price: '',
      },
    };
  },
  validations: {
    payload: {
      label: { required },
      price: { required },
    },
  },
  methods: {
    async getStructure() {
      try {
        const response = await StructureService.get(this.id);
        this.payload = response.data;
      } catch (error) {
        this.error(error);
      }
    },
    async save() {
      this.$v.payload.$touch();
      if (!this.$v.payload.$invalid) {
        try {
          const { data } = await this.id ? StructureService.edit(this.payload) : StructureService.save(this.payload);
          console.log(data);

          if (this.id) {
            this.messageSuccess('Estrutura editada com sucesso!');
          } else {
            this.messageSuccess('Estrutura cadastrada com sucesso!');
          }
        } catch (error) {
          console.log(error);
        }
      }

      this.$router.push({ name: 'structure' });
    },
  },
  created() {
    if (this.id) {
      this.getStructure();
    }
  },
};
</script>
